<div class="con2">
    <div class="container">
        <div class="row justify-content-md-center">
            <div class="card mb-3">
                <img class="card-img-top" src="assets/images/fachadaslide.png" alt="Card image cap">
                <div class="card-body">
                    <h5 class="card-title">QUIENES SOMOS</h5>
                    <p class="card-text "> El Hogar Gerontológico San José de Amor es una institución privada ubicada en
                        la
                        ciudad de Bogotá, localidad de Suba barrio Puente Largo, conformado por un equipo
                        multidisciplinar que
                        presta servicios gerontológicos cuidados de salud , estadía y Club House para la población
                        adulto mayor,
                        con ocho años de experiencia y calidez humana.
                    </p>
                    <p class="card-text"><small class="text-muted">Experiencia y calidez humana</small></p>
                </div>
            </div>
        </div>
        <div class="row justify-content-md-center">
            <div id="accordion" style="width: 100%;">
                <div class="card">
                    <div class="card-header" id="headingOne">
                        <h5 class="mb-0">
                            <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne"
                                aria-expanded="true" aria-controls="collapseOne">
                                Misión
                            </button>
                        </h5>
                    </div>
                    <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                        <div class="card-body">
                            <p class="card-text card-text-c">Prestar servicios especializados de salud, acompañamiento y
                                estadía de
                                calidad para la población adulto mayor que brinden seguridad y estabilidad a sus
                                familiares y al
                                paciente . Promoviendo el cuidado físico, emocional, mental y espiritual de nuestros
                                residentes,
                                mejorando así su calidad de vida.</p>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header" id="headingTwo">
                        <h5 class="mb-0">
                            <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo"
                                aria-expanded="false" aria-controls="collapseTwo">
                                Visión
                            </button>
                        </h5>
                    </div>
                    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                        <div class="card-body">
                            <p class="card-text card-text-c">Para el año 2023 ser una institución líder, reconocida por
                                su excelente
                                servicio y calidad humana, con reconocimientos sociales por nuestro aporte a la mejora
                                de la calidad de
                                vida del adulto mayor, expandiéndose en otros sectores de Bogotá y a nivel nacional.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>