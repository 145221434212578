<div class="con3">
    <div class="container">
        <h3 class="card-title">INSTALACIONES</h3>
        <p class="card-text ">En el hogar San José de Amor hemos perfeccionado cada uno de nuestros espacios, contamos
            con
            los mejores y más confortables espacios en el sector Gerontológico. </p>
        <ul>
            <li> Hermosas habitaciones de lujo, amplias y
                luminosas, con vista a nuestras espectaculares zonas verdes donde se pueden observar varias aves características de
                la
                ciudad.</li>
            <li> Zona social donde compartimos gratos momentos familiares, de juego, aprendizaje y esparcimiento.</li>
            <li>Sala de
                televisión amplia, confortable y con una gran vista al jardín trasero.</li>
            <li> Sistema de cámaras seguridad perimetral
                para su total tranquilidad.</li>
        </ul>
        <div class="row justify-content-md-center">
            <gallery [items]="images" [gestures]="true" [autoPlay]="true"></gallery>
        </div>
    </div>
</div>