<script>
    $('.carousel').carousel({
        interval: 20000,
        ride: true

    });
</script>
<div class="con1">
    <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
        <div class="carousel-inner carousel-inner-h">
            <div class="carousel-item active slideg slide1">
                <div id="pattern"></div>
                <div class="container">
                    <div class="row justify-content-md-center cont-size ">
                        <div class="col-12 align-self-center">
                            <div class="data">
                                <div class="data_down">
                                    <p class="title2">San José de <span>amor</span></p>
                                </div>
                            </div>

                        </div>
                        <div class="col-12 align-self-center">
                            <div class="data">
                                <div class="data_down">
                                    <p class="title1">Hogar gerontológico</p>
                                    <button type="button" class="btn btn-primary">Experiencia y calidez
                                        humana</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-item slideg slide2">
                <div class="container">
                    <div class="row justify-content-md-center cont-size slide2img">
                        <div class="col-12 align-self-end no-padding">
                            <div class="info-contact">
                                <div class="pattern">
                                    <h1>
                                        Contáctanos y separa tu cupo
                                    </h1>
                                    <table>
                                        <tr (click)="openMaps()">
                                            <td class="wsicon"><img src="assets/icons/location.svg"></td>
                                            <td class="info">TV 56 # 115-84 ILARCO</td>
                                        </tr>
                                        <tr (click)="openWhastApp()">
                                            <td class="wsicon"><img src="assets/icons/whatsapp.svg"></td>
                                            <td class="info">312 3 30 60 53</td>
                                        </tr>
                                        <tr (click)="callNow()">
                                            <td class="wsicon "><img src="assets/icons/call.svg"></td>
                                            <td class="info table-downoffset">5 25 58 16</td>
                                        </tr>
                                        <tr>
                                            <td class="wsicon " style="height: 50px; width: 20px;"></td>
                                            <td class="info table-downoffset"></td>
                                        </tr>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-item slideg slide3">
                <div class="container">
                    <div class="row justify-content-md-center cont-size ">
                        <!-- <div class="col-12 align-self-end no-padding">
                            <div class="intercol2-1">
                                <h1>
                                    Nuestros profesionales
                                </h1>
                                <p>
                                    Contamos con personal calificado para cumplir bla bla bla bla bla bla bla bla bla
                                    bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla
                                    bla bla bla
                                </p>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
        </a>
    </div>
</div>