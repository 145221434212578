<div class="con7">
    <div class="container">
        <h3 class="card-title">PROGRAMA CLUB HOUSE</h3>
        <p class="card-text ">Incentivados por tu comodidad y la del adulto mayor, creamos este programa donde tu
            familiar
            puede tener una pasadia con nosotros, sin necesidad de dejar el calor de su hogar, podrá compartir momentos
            agradables y disfrutar de nuestros cuidados, actividades recreativas y de esparcimiento, así estarás más
            tranquilo en tus horas laborales y el o ella totalmente agradado con nuestros cuidados. </p>
        <p>Dentro de Club House incluimos</p>
        <ul>
            <li>Horario de Ingreso y Salida 9:30 am - 4:30 pm</li>
            <li>Transporte Opcional</li>
            <li>3 comidas que incluyen, medias nueves, almuerzo balanceado y refrigerio saludable</li>
            <li>Cuidados de enfermería</li>
            <li>Musicoterapia</li>
            <li>Terapia ocupacional y de memoria</li>
            <li>Gimnasia pasiva y pilates</li>
            <li>Actividades familiares, culturales y recreativas.</li>
        </ul>
    </div>
</div>