import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor() {


  }

  ngOnInit(): void {

  }



  openWhastApp() {
    window.location.href = 'https://api.whatsapp.com/send?phone=573123306053';
  }

  openMaps() {
    window.location.href = 'https://www.google.com.sa/maps/search/4.699781%2C%20-74.068206?hl=es';
  }

  callNow() {
    window.location.href = 'tel:0315255816';
  }

}
