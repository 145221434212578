import { Component, OnInit } from '@angular/core';
import { GalleryItem, ImageItem } from '@ngx-gallery/core';

@Component({
  selector: 'app-instalaciones',
  templateUrl: './instalaciones.component.html',
  styleUrls: ['./instalaciones.component.scss']
})
export class InstalacionesComponent implements OnInit {

  images: GalleryItem[];

  constructor() { }

  ngOnInit(): void {
    this.images = [
      new ImageItem({
        thumb: 'assets/galeria/small/1.jpg',
        src: 'assets/galeria/1.jpg',
      }),
      new ImageItem({
        thumb: 'assets/galeria/small/2.jpg',
        src: 'assets/galeria/2.jpg',
      }),
      new ImageItem({
        thumb: 'assets/galeria/small/3.jpg',
        src: 'assets/galeria/3.jpg',
      }),
      new ImageItem({
        thumb: 'assets/galeria/small/4.jpg',
        src: 'assets/galeria/4.jpg',
      }),
      new ImageItem({
        thumb: 'assets/galeria/small/5.jpg',
        src: 'assets/galeria/5.jpg',
      }),
      new ImageItem({
        thumb: 'assets/galeria/small/6.jpg',
        src: 'assets/galeria/6.jpg',
      }),
      new ImageItem({
        thumb: 'assets/galeria/small/7.jpg',
        src: 'assets/galeria/7.jpg',
      }),
      new ImageItem({
        thumb: 'assets/galeria/small/8.jpg',
        src: 'assets/galeria/8.jpg',
      }),
      new ImageItem({
        thumb: 'assets/galeria/small/9.jpg',
        src: 'assets/galeria/9.jpg',
      }),
      new ImageItem({
        thumb: 'assets/galeria/small/10.jpg',
        src: 'assets/galeria/10.jpg',
      }),
      new ImageItem({
        thumb: 'assets/galeria/small/11.jpg',
        src: 'assets/galeria/11.jpg',
      }),
      new ImageItem({
        thumb: 'assets/galeria/small/12.jpg',
        src: 'assets/galeria/12.jpg',
      }),
      new ImageItem({
        thumb: 'assets/galeria/small/13.jpg',
        src: 'assets/galeria/13.jpg',
      }),
      new ImageItem({
        thumb: 'assets/galeria/small/14.jpeg',
        src: 'assets/galeria/14.jpeg',
      }),
      new ImageItem({
        thumb: 'assets/galeria/small/15.jpeg',
        src: 'assets/galeria/15.jpeg',
      }),
      new ImageItem({
        thumb: 'assets/galeria/small/16.jpeg',
        src: 'assets/galeria/16.jpeg',
      }),
      new ImageItem({
        thumb: 'assets/galeria/small/17.jpeg',
        src: 'assets/galeria/17.jpeg',
      }),
      new ImageItem({
        thumb: 'assets/galeria/small/18.jpeg',
        src: 'assets/galeria/18.jpeg',
      }),
      new ImageItem({
        thumb: 'assets/galeria/small/19.jpeg',
        src: 'assets/galeria/19.jpeg',
      }),
      new ImageItem({
        thumb: 'assets/galeria/small/20.jpeg',
        src: 'assets/galeria/20.jpeg',
      }),
      new ImageItem({
        thumb: 'assets/galeria/small/21.jpeg',
        src: 'assets/galeria/21.jpeg',
      }),

    ];
  }
}
